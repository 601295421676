import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  ${props => props.isShaking && css`animation: ${shakeAnimation} 0.5s ease-in-out;`}
`;

const ProgressBarText = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const FailureCounter = styled.div`
  font-size: 0.8rem;
  color: #ffffff;
  text-align: center;
  margin-top: 0.25rem;
`;

const ProgressBarOuter = styled.div`
  width: 100%;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const ProgressBarInner = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: ${props => props.isFailure ? '#FF0000' : '#4CAF50'};
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
`;

const ProgressBar = ({ progress, isEnabled, failures, isIncorrect, onFailure }) => {
  const [isShaking, setIsShaking] = useState(false);
  const [internalProgress, setInternalProgress] = useState(0);

  useEffect(() => {
    if (isEnabled) {
      setInternalProgress(isIncorrect ? 100 : progress);
    } else {
      setInternalProgress(Math.min(100, Math.floor(progress / 25) * 33.33));
    }
  }, [progress, isEnabled, isIncorrect]);

  useEffect(() => {
    if (failures > 0 && failures <= 3) {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      onFailure();
    }
  }, [failures, onFailure]);

  return (
    <ProgressBarContainer isShaking={isShaking}>
      <ProgressBarText>
        {isEnabled ? "YOUR TURN" : "HOW TO INTERACT"}
      </ProgressBarText>
      <ProgressBarOuter>
        <ProgressBarInner progress={internalProgress} isFailure={isIncorrect} />
      </ProgressBarOuter>
      {isEnabled && failures > 0 && (
        <FailureCounter>
          {failures}/3 Incorrect {failures === 3 ? "- CLUE GOES HERE!" : "- Try again!"}
        </FailureCounter>
      )}
    </ProgressBarContainer>
  );
};

export default ProgressBar;