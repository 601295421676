import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeVariants, transitionProps } from '../../utils/animations';
import Block from '../../components/ThreeWordModular/Block';
import ExampleBox from '../../components/ThreeWordModular/ExampleBox';
import SuccessFeedback from '../../components/ThreeWordModular/tutorial/results/SuccessFeedback';
import FailureFeedback from '../../components/ThreeWordModular/tutorial/results/FailureFeedback';
import ProgressBar from '../UI/ProgressBar';

import { getSentenceData, moveToNextLevel, getCurrentLevel, getTotalLevels, isLevelComplete } from '../../utils/SentenceData';

const Container = styled(motion.div)`
  width: 100%;
  max-width: 800px;
  position: relative;
`;

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const DropZone = styled(motion.div)`
  width: clamp(90px, calc(28vw - 1rem), 130px);
  height: clamp(60px, calc(17vw - 1rem), 90px);
  border: 2px dashed rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.2);
  }

  @media (min-width: 768px) {
    width: clamp(100px, calc(20vw - 1rem), 150px);
    height: clamp(70px, calc(12vw - 1rem), 100px);
  }

  @media (min-width: 1024px) {
    width: clamp(110px, calc(16vw - 1rem), 170px);
    height: clamp(75px, calc(10vw - 1rem), 110px);
  }
`;

const DropHerePlaceholder = styled.div`
  font-size: clamp(12px, calc(1vw + 8px), 18px);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 10px;
`;

const LevelDisplay = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const IncorrectFeedback = styled.div`
  color: #ffffff;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 1rem;
`;

const AvailableBlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const VerticalBlocksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MainComponent = () => {
  const [sentence, setSentence] = useState([null, null, null]);
  const [availableBlocks, setAvailableBlocks] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [draggedBlock, setDraggedBlock] = useState(null);
  const [progress, setProgress] = useState(0);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [currentSentence, setCurrentSentence] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadNewSentence();
  }, []);

  const loadNewSentence = () => {
    try {
      const newSentence = getSentenceData();
      if (!newSentence || !newSentence.maori || !newSentence.english || !newSentence.types || !newSentence.options) {
        throw new Error('Invalid sentence data');
      }
      setCurrentSentence(newSentence);
      setSentence([null, null, null]);
      setAvailableBlocks(
        newSentence.types.flatMap((type, index) => 
          newSentence.options[type].map((word, wordIndex) => ({
            id: `block-${index}-${wordIndex}`,
            content: word,
            type: type,
            color: getColorForType(type)
          }))
        )
      );
      setIncorrectAttempts(0);
      setIsIncorrect(false);
      setError(null);
      setProgress(0);
    } catch (err) {
      console.error('Error loading sentence:', err);
      setError('Failed to load sentence. Please try again.');
    }
  };

  const getColorForType = (type) => {
    switch (type) {
      case 'Tense Marker': return '#45bbff';
      case 'Action Word': return '#7ed957';
      case 'Pronoun': return '#ff5757';
      case 'Time': return '#ffa500';
      case 'Article': return '#9932cc';
      case 'Noun': return '#ff69b4';
      default: return '#ffffff';
    }
  };

  const handleDragStart = (e, block) => {
    setDraggedBlock(block);
    e.dataTransfer.setData('text/plain', block.id);
    e.dataTransfer.effectAllowed = 'move';
    e.target.style.opacity = '0.4';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const blockId = e.dataTransfer.getData('text');
    const droppedBlock = availableBlocks.find(block => block.id === blockId);
    
    if (droppedBlock) {
      const newSentence = [...sentence];
      const oldBlock = newSentence[index];
      newSentence[index] = droppedBlock;

      let newAvailableBlocks = availableBlocks.filter(block => block.id !== blockId);
      if (oldBlock) {
        newAvailableBlocks = [...newAvailableBlocks, oldBlock];
      }

      setSentence(newSentence);
      setAvailableBlocks(newAvailableBlocks);
      setDraggedBlock(null);

      updateProgress(newSentence);

      if (newSentence.every(word => word !== null)) {
        if (newSentence.map(block => block.content).join(' ') === currentSentence.maori.join(' ')) {
          handleSuccess();
        } else {
          handleFailure();
        }
      }
    }
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
    setDraggedBlock(null);
  };

  const updateProgress = (newSentence) => {
    const filledSlots = newSentence.filter(block => block !== null).length;
    const newProgress = (filledSlots / currentSentence.maori.length) * 100;
    setProgress(newProgress);
  };

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      if (isLevelComplete()) {
        moveToNextLevel();
      }
      loadNewSentence();
    }, 2000);
    setIncorrectAttempts(0);
    setIsIncorrect(false);
  };

  const handleFailure = () => {
    setShowFailure(true);
    setIsIncorrect(true);
    setIncorrectAttempts(prev => Math.min(prev + 1, 3));
    setTimeout(() => {
      setShowFailure(false);
      if (incorrectAttempts < 2) {
        setIsIncorrect(false);
        resetBlocks();
      } else {
        loadNewSentence();
        setIncorrectAttempts(0);
        setIsIncorrect(false);
      }
    }, 2000);
  };

  const resetBlocks = () => {
    setSentence([null, null, null]);
    setAvailableBlocks(
      currentSentence.types.flatMap((type, index) => 
        currentSentence.options[type].map((word, wordIndex) => ({
          id: `block-${index}-${wordIndex}`,
          content: word,
          type: type,
          color: getColorForType(type)
        }))
      )
    );
    setProgress(0);
  };

  if (error) {
    return <div style={{ color: 'white', textAlign: 'center' }}>{error}</div>;
  }

  if (!currentSentence) {
    return <div style={{ color: 'white', textAlign: 'center' }}>Loading...</div>;
  }

  return (
    <Container
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      transition={transitionProps}
    >
      <LevelDisplay>LEVEL {getCurrentLevel() + 1}</LevelDisplay>
    
      <ProgressBar 
        progress={progress}
        isEnabled={true}
        incorrectAttempts={incorrectAttempts}
        isIncorrect={isIncorrect}
      />
      
      {isIncorrect && (
        <IncorrectFeedback>
          {incorrectAttempts}/3 Incorrect - try again!
        </IncorrectFeedback>
      )}

      <ExampleBox>
        {currentSentence.english.map((word, index) => (
          <React.Fragment key={index}>
            {word}
            {' '}
          </React.Fragment>
        ))}
      </ExampleBox>

      <BlocksContainer>
        <AnimatePresence>
          {sentence.map((block, index) => (
            <DropZone
              key={index}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              {block ? (
                <Block 
                  {...block} 
                  isInDropZone={true}
                />
              ) : (
                <DropHerePlaceholder>Drop here</DropHerePlaceholder>
              )}
            </DropZone>
          ))}
        </AnimatePresence>
      </BlocksContainer>

      <AvailableBlocksContainer>
        {currentSentence.types.map((type) => (
          <VerticalBlocksContainer key={type}>
            {availableBlocks
              .filter(block => block.type === type)
              .map((block) => (
                <motion.div
                  key={block.id}
                  draggable={true}
                  onDragStart={(e) => handleDragStart(e, block)}
                  onDragEnd={handleDragEnd}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                >
                  <Block 
                    {...block} 
                    isDragging={draggedBlock?.id === block.id}
                  />
                </motion.div>
              ))}
          </VerticalBlocksContainer>
        ))}
      </AvailableBlocksContainer>

      {showSuccess && <SuccessFeedback />}
      {showFailure && <FailureFeedback />}
    </Container>
  );
};

export default MainComponent;