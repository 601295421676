import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const StyledBlock = styled(motion.div)`
  background: ${props => props.$bgColor || '#4ecdc4'};
  color: #1a1a2e;
  padding: 0.5rem;
  border-radius: 12px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: clamp(90px, calc(28vw - 1rem), 130px);
  height: clamp(60px, calc(17vw - 1rem), 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.$isInDropZone ? 'default' : 'grab'};

  ${props => props.$isDragging && css`
    cursor: grabbing;
  `}

  @media (min-width: 768px) {
    width: clamp(100px, calc(20vw - 1rem), 150px);
    height: clamp(70px, calc(12vw - 1rem), 100px);
  }

  @media (min-width: 1024px) {
    width: clamp(110px, calc(16vw - 1rem), 170px);
    height: clamp(75px, calc(10vw - 1rem), 110px);
  }
`;

const Content = styled.div`
  font-size: clamp(12px, calc(1vw + 8px), 18px);
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const Type = styled.small`
  font-size: clamp(9px, calc(0.8vw + 6px), 14px);
  opacity: 0.7;
`;

const Block = ({ id, content, type, color, isHighlighted, isInDropZone, isDragging }) => (
  <StyledBlock
    $bgColor={color}
    $isInDropZone={isInDropZone}
    $isDragging={isDragging}
    initial={{ opacity: 1, scale: 1 }}
    animate={isDragging ? { opacity: 0.8, scale: 1.05 } : { opacity: 1, scale: 1 }}
    whileHover={!isInDropZone ? { 
      scale: 1.05, 
      boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
      transition: { duration: 0.2 }
    } : {}}
    whileTap={!isInDropZone ? { scale: 0.95 } : {}}
    transition={{ duration: 0.2 }}
  >
    <Content>{content}</Content>
    <Type>{type}</Type>
  </StyledBlock>
);

export default Block;