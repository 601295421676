// Menu.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const MenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  position: fixed; /* Ensure it covers the entire viewport */
  top: 0;
  left: 0;
  overflow: hidden; /* Optional: Hide any overflowing content */
`;


const WelcomeText = styled.h1`
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MenuItem = styled(motion.button)`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const menuItems = [
  { name: "3 Word Modular", path: "/threemodular" },
  { name: "Identification Modular", path: "/identificationmodular" },
  { name: "Actions & Tenses", path: "/actionstenses" },
  { name: "Pepeha Modular", path: "/pepehamodular" }
];

const Menu = () => {
  const navigate = useNavigate();

  const handleSelectModule = (path) => {
    navigate(path);
  };

  return (
    <MenuContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <WelcomeText>Dev Navigation</WelcomeText>
      <MenuItemsContainer>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelectModule(item.path)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuItemsContainer>
    </MenuContainer>
  );
};

export default Menu;
