import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fadeVariants, transitionProps } from '../../utils/animations';

const WelcomeContainer = styled(motion.div)`
  background-color: black;
  padding: 1rem;
  border: 2px solid white;
  display: inline-block;
  max-width: 90vw;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const WelcomeText = styled.h1`
  font-size: clamp(1.5rem, 5vw, 3rem);
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.05em;
  color: white;
  margin: 0;
  padding: 0.5rem 0;
  font-family: 'Times New Roman', serif;
`;

const Welcome = ({ onComplete }) => {
  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    const timer = setTimeout(handleComplete, 4000);
    return () => clearTimeout(timer);
  }, [handleComplete]);

  return (
    <WelcomeContainer
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={transitionProps}
      aria-live="polite"
    >
      <WelcomeText>
        Welcome to Pūnaha Reo
      </WelcomeText>
    </WelcomeContainer>
  );
};

export default React.memo(Welcome);