const WordType = Object.freeze({
  TENSE_MARKER: 'Tense Marker',
  ACTION_WORD: 'Action Word',
  PRONOUN: 'Pronoun',
  TIME: 'Time',
  ARTICLE: 'Article',
  NOUN: 'Noun',
});

const wordLists = {
  [WordType.TENSE_MARKER]: ['Kua', 'Ka', 'Kei te', 'I', 'E', 'Me'],
  [WordType.ACTION_WORD]: ['peke', 'oma', 'kai', 'tu', 'noho', 'tangi', 'moe', 'kaukau', 'waiata', 'titiro', 'hikoi', 'whakaaro', 'rere', 'tuhi', 'tunu', 'kanikani', 'kōrero', 'whakarongo', 'whakaako', 'haereere', 'āwhina', 'whakanui', 'ako'],
  [WordType.PRONOUN]: ['koe', 'ahau', 'ia', 'mātou', 'rātou', 'tātou', 'koutou', 'au', 'kōrua', 'māua', 'rāua', 'tāua']
};

const createSentence = (english, maori, types) => {
  const options = {};
  types.forEach((type, index) => {
    const correctWord = maori[index];
    const otherWords = wordLists[type].filter(word => word !== correctWord);
    const randomWords = getRandomElements(otherWords, 2);
    options[type] = shuffleArray([correctWord, ...randomWords]);
  });
  return { english, maori, types, options };
};

const getRandomElements = (array, n) => {
  const shuffled = [...array].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const levels = [
  {
    sentences: [
      createSentence(
        ['You', 'have', 'jumped'],
        ['Kua', 'peke', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'am', 'eating'],
        ['Kei te', 'kai', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['He', 'will', 'sleep'],
        ['Ka', 'moe', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'are', 'sitting'],
        ['Kei te', 'noho', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['She', 'has', 'smiled'],
        ['Kua', 'menemene', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'will', 'run'],
        ['Ka', 'oma', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'have', 'played'],
        ['Kua', 'takaro', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['He', 'is', 'swimming'],
        ['Kei te', 'kaukau', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'will', 'sing'],
        ['Ka', 'waiata', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'have', 'jumped'],
        ['Kua', 'peke', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['She', 'is', 'looking'],
        ['Kei te', 'titiro', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'have', 'walked'],
        ['Kua', 'hikoi', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'are', 'thinking'],
        ['Kei te', 'whakaaro', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['He', 'will', 'fly'],
        ['Ka', 'rere', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'have', 'stood'],
        ['Kua', 'tu', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
    ]
  },
  {
    sentences: [
      createSentence(
        ['I', 'am', 'writing'],
        ['Kei te', 'tuhi', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'will', 'cook'],
        ['Ka', 'tunu', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['She', 'has', 'danced'],
        ['Kua', 'kanikani', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['We', 'are', 'talking'],
        ['Kei te', 'kōrero', 'mātou'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['They', 'will', 'listen'],
        ['Ka', 'whakarongo', 'rātou'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
    ]
  },
  {
    sentences: [
      createSentence(
        ['He', 'has', 'taught'],
        ['Kua', 'whakaako', 'ia'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['I', 'will', 'travel'],
        ['Ka', 'haereere', 'ahau'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['You', 'are', 'helping'],
        ['Kei te', 'āwhina', 'koe'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['We', 'have', 'celebrated'],
        ['Kua', 'whakanui', 'tātou'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
      createSentence(
        ['They', 'will', 'study'],
        ['Ka', 'ako', 'rātou'],
        [WordType.TENSE_MARKER, WordType.ACTION_WORD, WordType.PRONOUN]
      ),
    ]
  },
];

let currentLevel = 0;
let remainingSentences = [];

const resetLevel = () => {
  remainingSentences = [...levels[currentLevel].sentences];
  shuffleArray(remainingSentences);
};

export const getSentenceData = () => {
  if (remainingSentences.length === 0) {
    resetLevel();
  }
  return remainingSentences.pop();
};

export const moveToNextLevel = () => {
  currentLevel = (currentLevel + 1) % levels.length;
  resetLevel();
};

export const getCurrentLevel = () => currentLevel;

export const getTotalLevels = () => levels.length;

export const isLevelComplete = () => remainingSentences.length === 0;

resetLevel();

export default levels;