import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeVariants, transitionProps } from '../../../utils/animations';
import { BLOCKS } from '../../../constants/blocks';
import Block from '../Block';
import ExampleBox from '../ExampleBox';
import SuccessFeedback from './results/SuccessFeedback';
import FailureFeedback from './results/FailureFeedback';
import ProgressBar from '../../UI/ProgressBar';
import MainComponent from '../MainComponent';

const Container = styled(motion.div)`
  width: 100%;
  max-width: 800px;
  position: relative;
`;

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const DropZone = styled(motion.div)`
  width: clamp(90px, calc(28vw - 1rem), 130px);
  height: clamp(60px, calc(17vw - 1rem), 90px);
  border: 2px dashed rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.2);
  }

  @media (min-width: 768px) {
    width: clamp(100px, calc(20vw - 1rem), 150px);
    height: clamp(70px, calc(12vw - 1rem), 100px);
  }

  @media (min-width: 1024px) {
    width: clamp(110px, calc(16vw - 1rem), 170px);
    height: clamp(75px, calc(10vw - 1rem), 110px);
  }
`;

const DropHerePlaceholder = styled.div`
  font-size: clamp(12px, calc(1vw + 8px), 18px);
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 10px;
`;

const TutorialText = styled.div`
  color: #ffffff;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const highlightAnimation = keyframes`
  0% { transform: translateY(0); box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
  50% { transform: translateY(-5px); box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.7); }
  100% { transform: translateY(0); box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
`;

const HighlightWrapper = styled.span`
  display: inline-block;
  ${css`animation: ${highlightAnimation} 1s ease-in-out 3;`}
`;

const Tutorial = () => {
  const [sentence, setSentence] = useState(['', '', '']);
  const [availableBlocks, setAvailableBlocks] = useState(BLOCKS);
  const [highlightedElement, setHighlightedElement] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [demoProgress, setDemoProgress] = useState(0);
  const [interactiveProgress, setInteractiveProgress] = useState(0);
  const [tutorialStage, setTutorialStage] = useState('demo');
  const [failures, setFailures] = useState(0);
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [showMainComponent, setShowMainComponent] = useState(false);

  const correctOrder = ['Kua', 'Tangi', 'Koe'];

  useEffect(() => {
    if (tutorialStage === 'demo') {
      const tutorialSteps = [
        { delay: 1000, type: 'Tense Marker', word: 'Kua' },
        { delay: 5000, type: 'Action Word', word: 'Tangi' },
        { delay: 9000, type: 'Pronoun', word: 'Koe' },
        { delay: 13000, type: null }
      ];

      let timeoutIds = [];

      tutorialSteps.forEach(({ delay, type, word }, index) => {
        const timeoutId = setTimeout(() => {
          setHighlightedElement(type);
          if (word) {
            setSentence(prev => {
              const newSentence = [...prev];
              newSentence[index] = word;
              return newSentence;
            });
            setAvailableBlocks(prev => prev.filter(block => block.content !== word));
          }
          setDemoProgress((index + 1) * 25);
          
          const resetTimeout = setTimeout(() => {
            setHighlightedElement(null);
          }, 3000);
          
          timeoutIds.push(resetTimeout);
        }, delay);
        timeoutIds.push(timeoutId);
      });

      const finalTimeout = setTimeout(() => {
        setTutorialStage('interactive');
        resetBlocks();
      }, 15000);
      timeoutIds.push(finalTimeout);

      return () => timeoutIds.forEach(clearTimeout);
    }
  }, [tutorialStage]);

  useEffect(() => {
    if (tutorialStage === 'interactive' && !isIncorrect) {
      const filledSlots = sentence.filter(word => word !== '').length;
      setInteractiveProgress((filledSlots / correctOrder.length) * 100);
    }
  }, [sentence, tutorialStage, isIncorrect]);

  const handleDragStart = (e, block) => {
    if (tutorialStage === 'interactive') {
      e.dataTransfer.setData('text/plain', JSON.stringify(block));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    if (tutorialStage === 'interactive') {
      const droppedBlock = JSON.parse(e.dataTransfer.getData('text'));
      const newSentence = [...sentence];
      const oldWord = newSentence[index];
      newSentence[index] = droppedBlock.content;

      let newAvailableBlocks = availableBlocks.filter(block => block.id !== droppedBlock.id);
      if (oldWord) {
        newAvailableBlocks = [...newAvailableBlocks, getBlockForWord(oldWord)];
      }

      setSentence(newSentence);
      setAvailableBlocks(newAvailableBlocks);

      if (newSentence.every(word => word !== '')) {
        if (newSentence.join(' ') === correctOrder.join(' ')) {
          handleSuccess();
        } else {
          handleFailure();
        }
      }
    }
  };

  const handleFailure = () => {
    setShowFailure(true);
    setIsIncorrect(true);
    setFailures(prev => prev + 1);
    setTimeout(() => {
      setShowFailure(false);
      setIsIncorrect(false);
      resetBlocks();
    }, 2000);
  };

  const resetBlocks = () => {
    setSentence(['', '', '']);
    setAvailableBlocks(BLOCKS);
    setInteractiveProgress(0);
  };

  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setShowMainComponent(true);
      localStorage.setItem('tutorialCompleted', 'true');
    }, 2000);
    setFailures(0);
    setIsIncorrect(false);
  };

  const getBlockForWord = (word) => {
    return BLOCKS.find(block => block.content === word);
  };

  if (showMainComponent) {
    return <MainComponent />;
  }

  const exampleWords = [
    { word: 'You', type: 'Pronoun' },
    { word: 'have', type: 'Tense Marker' },
    { word: 'cried', type: 'Action Word' }
  ];

  return (
    <Container
      variants={fadeVariants}
      initial="initial"
      animate="animate"
      transition={transitionProps}
    >
      <ProgressBar 
        progress={tutorialStage === 'demo' ? demoProgress : interactiveProgress}
        isEnabled={tutorialStage === 'interactive'}
        failures={failures}
        isIncorrect={isIncorrect}
        onFailure={() => {}}
      />
      
    

      <ExampleBox>
        {exampleWords.map(({ word, type }, index) => (
          <React.Fragment key={index}>
            {highlightedElement === type ? (
              <HighlightWrapper>{word}</HighlightWrapper>
            ) : (
              word
            )}
            {' '}
          </React.Fragment>
        ))}
      </ExampleBox>

      <BlocksContainer>
        <AnimatePresence>
          {sentence.map((word, index) => (
            <DropZone
              key={index}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              {word ? (
                <Block 
                  {...getBlockForWord(word)} 
                  isHighlighted={highlightedElement === getBlockForWord(word)?.type}
                  isInDropZone={true}
                />
              ) : (
                <DropHerePlaceholder>Drop here</DropHerePlaceholder>
              )}
            </DropZone>
          ))}
        </AnimatePresence>
      </BlocksContainer>

      <BlocksContainer>
        {availableBlocks.map((block) => (
          <div
            key={block.id}
            draggable={tutorialStage === 'interactive'}
            onDragStart={(e) => handleDragStart(e, block)}
          >
            <Block 
              {...block} 
              isHighlighted={highlightedElement === block.type}
              isDisabled={tutorialStage === 'demo'}
            />
          </div>
        ))}
      </BlocksContainer>

      {showSuccess && <SuccessFeedback />}
      {showFailure && <FailureFeedback />}
    </Container>
  );
};

export default Tutorial;