
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Menu from './Menu';
import ThreeWordModular from './components/ThreeWordModular';
import audioManager from './utils/audioManager'; 

const App = () => {
  const isDevMode = process.env.REACT_APP_MODE === 'dev';
  console.log('isDevMode:', isDevMode);

  useEffect(() => {
    audioManager.preloadAudio().then(() => {
      console.log('Audio preloaded successfully');
    }).catch(error => {
      console.error('Error preloading audio:', error);
    });
  }, []);

  return (
    <Router>
      <Routes>
        {isDevMode ? (
          <>
            <Route path="/" element={<Menu />} />
            <Route path="/threemodular" element={<ThreeWordModular />} />
            {/* <Route path="/identificationmodular" element={<IdentificationModular />} /> */}
            {/* <Route path="/actionstenses" element={<ActionsAndTenses />} /> */}
            {/* <Route path="/pepehamodular" element={<PepehaModular />} /> */}
          </>
        ) : (
          <>
            <Route path="/threemodular" element={<ThreeWordModular />} />
            <Route path="*" element={<Navigate to="/threemodular" replace />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;