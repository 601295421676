import React from 'react';
import styled from 'styled-components';

const StyledExampleBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
`;

const ExampleBox = ({ children }) => (
  <StyledExampleBox>{children}</StyledExampleBox>
);

export default ExampleBox;