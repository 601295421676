import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Tutorial from '../ThreeWordModular/tutorial/Tutorial';
import MainComponent from '../ThreeWordModular/MainComponent';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
`;

const IntroContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const IntroText = styled.div`
  font-size: clamp(0.8rem, 3vw, 1.5rem);
  font-weight: 600;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  animation: ${fadeIn} 1.5s ease-out forwards;

  @media (min-width: 768px) {
    font-size: clamp(1.2rem, 2.5vw, 2rem);
  }

  ${props => props.$isFadingOut && css`
    animation: ${fadeOut} 1s ease-in forwards;
  `}
`;

const flowingUnderline = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const FlowingText = styled.span`
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    background: linear-gradient(90deg, #FF0000, #000000);
    animation: ${flowingUnderline} 3s forwards;
  }
`;

const highlightEffect = keyframes`
  0% { transform: translateY(0); background-color: transparent; }
  100% { transform: translateY(-3px); }
`;

const HighlightSpan = styled.span`
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding: 0 3px;
  transition: all 0.3s ease;

  ${props => props.$isActive && css`
    animation: ${highlightEffect} 0.3s forwards;
    background-color: ${props => props.$highlightColor};
  `}

  ${props => !props.$isActive && css`
    transform: translateY(0);
    background-color: transparent;
  `}
`;

const KeyTermsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0;
`;

const SkipButtonContainer = styled(motion.div)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
`;

const TermSeparator = styled.span`
  margin: 0 0.25rem;
`;

const StyledButton = styled(motion.button)`
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: 2px solid white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const buttonVariants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const SkipButton = ({ onClick }) => (
  <SkipButtonContainer
    initial="initial"
    animate="animate"
    exit="exit"
    variants={buttonVariants}
  >
    <StyledButton
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      Skip
    </StyledButton>
  </SkipButtonContainer>
);

const Introduction = () => {
  const [animationStep, setAnimationStep] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [showNextComponent, setShowNextComponent] = useState(false);

  const tutorialCompleted = useMemo(() => localStorage.getItem('tutorialCompleted') === 'true', []);

  const nextComponent = useMemo(() => 
    tutorialCompleted ? <MainComponent /> : <Tutorial />,
  [tutorialCompleted]);

  useEffect(() => {
    const timings = [2000, 3000, 1500, 1500, 2000, 1000];
    let cumulativeTime = 0;

    const timers = timings.map((time, index) => {
      cumulativeTime += time;
      return setTimeout(() => {
        setAnimationStep(index + 1);
        if (index === 4) setIsFadingOut(true);
        if (index === 5) setShowNextComponent(true);
      }, cumulativeTime);
    });

    return () => timers.forEach(clearTimeout);
  }, []);

  const handleSkip = useCallback(() => {
    setShowNextComponent(true);
  }, []);

  if (showNextComponent) {
    return nextComponent;
  }

  return (
    <IntroContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AnimatePresence>
        {!showNextComponent && (
          <SkipButton onClick={handleSkip} />
        )}
      </AnimatePresence>
      <ContentWrapper>
        <IntroText $isFadingOut={isFadingOut}>
          <FlowingText>
            In Māori, basic sentences often follow a 3-word structure:
          </FlowingText>
          <KeyTermsWrapper>
            <HighlightSpan $isActive={animationStep === 2} $highlightColor="#45bbff">Tense Marker</HighlightSpan>
            <TermSeparator>,</TermSeparator>
            <HighlightSpan $isActive={animationStep === 3} $highlightColor="#7ed957">Action Word</HighlightSpan>
            <TermSeparator>,</TermSeparator>
            <HighlightSpan $isActive={animationStep === 4} $highlightColor="#ff5757">Pronoun</HighlightSpan>
          </KeyTermsWrapper>
          Let's practise building sentences using these blocks.
        </IntroText>
      </ContentWrapper>
    </IntroContainer>
  );
};

export default React.memo(Introduction);