import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const popIn = keyframes`
  0% { transform: translate(-50%, -50%) scale(0.5); opacity: 0; }
  100% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  background-color: #4CAF50;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  opacity: 0;
  animation: ${popIn} 0.5s ease-out forwards;
`;

const SuccessFeedback = () => {
  const audioRef = useRef(new Audio('./success.mp3'));

  useEffect(() => {
    // Preload the audio
    audioRef.current.load();

    // Play audio as soon as the component mounts
    const playAudio = async () => {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    };

    playAudio();

    // Cleanup function
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <Container>
      Correct! Great job!
    </Container>
  );
};

export default SuccessFeedback;