import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import GlobalStyles from '../styles/GlobalStyles';
import Welcome from './ThreeWordModular/Welcome';
import Introduction from './ThreeWordModular/Introduction';
import Tutorial from './ThreeWordModular/tutorial/Tutorial';

const AppContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const ThreeWordModular = () => {
  const [step, setStep] = useState(0);

  const handleStepComplete = useCallback(() => {
    setStep(prevStep => prevStep + 1);
  }, []);

  return (
    <>
      <GlobalStyles />
      <AppContainer>
        <AnimatePresence mode="wait">
          {step === 0 && <Welcome key="welcome" onComplete={handleStepComplete} />}
          {step === 1 && <Introduction key="intro" onComplete={handleStepComplete} />}
          {step === 2 && <Tutorial key="dragdrop" />}
        </AnimatePresence>
      </AppContainer>
    </>
  );
};

export default ThreeWordModular