import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const shake = keyframes`
  0% { transform: translate(-50%, -50%) translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-50%, -50%) translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-50%, -50%) translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(-50%, -50%) translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(-50%, -50%) translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-50%, -50%) translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-50%, -50%) translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(-50%, -50%) translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-50%, -50%) translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(-50%, -50%) translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(-50%, -50%) translate(1px, -2px) rotate(-1deg); }
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F44336;
  color: white;
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  animation: ${shake} 0.5s ease-in-out;
`;

const FailureFeedback = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch(error => console.error(error));
    }
  }, []);

  return (
    <Container>
      <audio ref={audioRef} src="./error.mp3" preload="auto"></audio>
      Not quite right. Try again!
    </Container>
  );
};

export default FailureFeedback;
