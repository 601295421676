// audioManager.js
const audioManager = {
  successAudio: new Audio('./success.mp3'),
  isLoaded: false,

  preloadAudio: function() {
    return new Promise((resolve) => {
      if (this.isLoaded) {
        resolve();
        return;
      }

      this.successAudio.addEventListener('canplaythrough', () => {
        this.isLoaded = true;
        resolve();
      }, { once: true });

      this.successAudio.load();
    });
  },

  playSuccess: function() {
    if (this.isLoaded) {
      this.successAudio.currentTime = 0;
      this.successAudio.play().catch(error => console.error('Error playing audio:', error));
    } else {
      console.warn('Audio not loaded yet. Call preloadAudio first.');
    }
  }
};

export default audioManager;